<template>
  <div name="activityReport">

  <section class="bg-white rounded w-95p mx-auto px-6 pb-6 pt-4 mt-4 mb-8" >
       <h4 class="text-lg font-semibold mb-6">Participating in Extra Curricular</h4>
      
        <div v-if="componentLoaded">
                <div class="grid grid-cols-1 w-10/12 mx-auto text-center" >
                  <h5 class="font-bold mb-5 ml-2">Number of clubs pupils attend</h5>
                    <barChart :data-source="reppingActivityVisit" ></barChart>

                </div>

                    <div class="grid grid-cols-2 gap-4 mt-8">

                    <div>
    
                        <donutChart  :data-source="reppingActivity" :chart-title="'In an activity'"></donutChart>
                    </div>


                     <div>
                 
                         <donutChart  :data-source="reppingActivityAtTimeFrame" :totalOveride="activities.length" :chart-title="'At timeframes'"></donutChart>
                    </div>

                  </div>
                </div>

                   <p class="empty" v-else>We could not find any data for your given selection.</p>
                </section>


  <section class="bg-white rounded w-95p mx-auto px-6 pb-6 pt-4 mt-4 mb-8" >
       <h4 class="text-lg font-semibold mb-6">Activities in Extra Curricular</h4>
        

                    <div class="grid grid-cols-2 gap-4" v-if="componentLoaded">

                     <div>
                         <donutChart :data-source="timeFrameChartData" :totalOveride="activities.length" :chart-title="'By time frame'"></donutChart>
                    </div>

                      <div>
            
                         <donutChart :data-source="typeChartData" :chart-title="'By type'"></donutChart>
                      </div>

                  </div>

                   <p class="empty" v-else>We could not find any data for your given selection.</p>
                </section>

  </div>
</template>

<script>
const donutChart = () => import("@/components/charts/DonutChart.vue");
const barChart = () => import("@/components/charts/BarChart.vue");


export default {
  name: "activityReport",
    props: {
      pupilCount: {type: Number, required: true},
      activities: {type: Array, required: true},
  },
  computed: {
    componentLoaded() {
      return this.activities.length > 0;
    },
    reppingActivityVisit() {
      let vm = this
      var registers = vm.activities.flatMap(function(a) {
        if (a.registers != undefined) {
            return a.registers.map(r => r.identifier)
        }
      });

      var counts = {};
      registers.filter((obj) => obj).forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
      let countArray = Object.keys(counts).map(key => ({ count: counts[key] }))

      let results =  Array.from(Array(10)).map(function(_, i) {
        let value = countArray.filter(function(c) {
          return c.count === i + 1
        })

        return { "name": i + 1, "value": value.length, "color": "#A3E9D2" }
      })

      return results
    },
    reppingActivity() {
      if (!this.componentLoaded) {
        return null;
      }

      let unique = this.uniqueRepping();

      return [
        { name: "Attending", value: unique, color: "#A3E9D2" },
        {
          name: "Non-attending",
          value: this.pupilCount - unique,
          color: "#FBB3B3"
        }
      ];
    },
    reppingActivityAtTimeFrame() {
      if (!this.componentLoaded) {
        return null;
      }

      var list = this.activities.filter(function(row) {
        let filtered = true;
        filtered = row.registers != null && row.registers.length > 0;

        return filtered;
      });

      let data = this.timeFrames.map(timeframe => {
        var filtered = list.filter(activity => {
          let filtered = true;
          filtered = activity.timeframe.includes(timeframe.value);
          return filtered;
        });

        let repping = filtered.flatMap(function(activity) {
          return activity.registers.map(function(reg) {
            return reg.identifier;
          });
        });

        //let unique = Array.from(new Set(repping)).length;
        let unique = Array.from(repping).length;

        return { name: timeframe.title, value: unique, color: timeframe.color };
      });

      return data;
    },
    timeFrameChartData() {
      if (!this.componentLoaded) {
        return null;
      }

      let activityList = this.activities;
      let data = this.timeFrames.map(timeframe => {
        var list = activityList.filter(activity => {
          let filtered = true;
          filtered = activity.timeframe.includes(timeframe.value);
          return filtered;
        });

        return {
          name: timeframe.title,
          value: list.length,
          color: timeframe.color
        };
      });

      return data;
    },
    typeChartData() {
      if (!this.componentLoaded) {
        return null;
      }

      return [
        {
          name: "Other",
          value: this.getCountByProgramType("other"),
          color: "#EBEBEB"
        },
        {
          name: "CPD",
          value: this.getCountByProgramType("cpd"),
          color: "#FFEAAA"
        },
        {
          name: "Swimming",
          value: this.getCountByProgramType("swimming"),
          color: "#A7C2FF"
        },
        {
          name: "Health",
          value: this.getCountByProgramType("health"),
          color: "#A3E9D2"
        },
        {
          name: "PE",
          value: this.getCountByProgramType("education"),
          color: "#FBB3B3"
        }
      ];
    }
  },
  data() {
    return {
      timeFrames: [
        { value: "breakfast", title: "Breakfast", color: "#A3E9D2" },
        { value: "lunch", title: "Lunch", color: "#FFEAAA" },
        { value: "after-school", title: "After School", color: "#A7C2FF" }
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    getCountByProgramType(type) {
      var list = this.activities.filter(activity => {
        let filtered = true;

        if (type == "education") {
          filtered =
            activity.session_type.String === type ||
            activity.session_type.String.length < 1;
        } else {
          filtered = activity.session_type.String === type;
        }

        return filtered;
      });

      return list.length;
    },
    uniqueRepping() {
      let list = this.activities.filter(activity => {
        let filtered = true;
        filtered = activity.registers != null && activity.registers.length > 0;

        return filtered;
      });

      let repping = list.flatMap(activity => {
        return activity.registers.map(reg => {
          return reg.identifier;
        });
      });

      console.log(Array.from(new Set(repping)).length);

      return Array.from(new Set(repping)).length;
    }
  },
  components: {
    donutChart,
    barChart
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#main .inner section.filter h4 {
  margin-bottom: 0px !important;
}

.upper {
  text-transform: uppercase;
}

.smaller {
  font-size: 75% !important;
}
</style>
